import api from '@api'
import {
    Asset,
    AssetBulkDeleteResponseDto,
    AssetBulkTagStateResponseDto,
    AssetBulkUpdateTagsResponseDto,
    AssetFavoriteResponseDto,
    AssetFeedbackDto,
    AssetPreviews,
    AssetTasksResponseDto,
    BulkUpdateAssetsDto,
    CreateAssetFeedbackDto,
    FlagAssetDto,
    LinkedAssetsImportResult,
    NewVersionDto,
    RequestChangeDto,
    RequestChangeResult,
    RequestNewAssetDto,
    UpdateAssetDto,
} from '../@types/asset'

export const createAsset = async (file: File, onUploadProgress?: (progressEvent: ProgressEvent) => void) => {
    const formData = new FormData()
    formData.append("file", file)
    // we specify a timeout to ensure it doesn't ever get removed.
    // axios's default is 0 (infinite), but we want to protect an accidental update
    const response = await api.post<Asset>("/api/v1/assets", formData, {
        headers: {"Content-Type": "multipart/form-data"},
        timeout: 1000 * 60 * 60, // 60 minutes
        onUploadProgress
    })
    return response.data
}

export const createLinkedAsset = async (location: string) => {
    const response = await api.post<Asset>("/api/v1/assets/linked", { location })
    return response.data
}

export const bulkUploadLinkedAssets = async(file: File) => {
    const formData = new FormData()
    formData.append("file", file)
    const response = await api.post<LinkedAssetsImportResult>("/api/v1/assets/linked/bulk-create", formData, {
        headers: {"Content-Type": "multipart/form-data"}
    })
    return response.data
}

export const createAssets = async (files: File[]) => {
    const formData = new FormData()
    files.forEach(it => {
        formData.append("files", it)
    })
    const response = await api.post<Asset>("/api/v1/assets/bulk", formData, {
        headers: {"Content-Type": "multipart/form-data"}
    })
    return response.data
}

export const updateAsset = async (assetId: string, asset: UpdateAssetDto) => {
    const response = await api.put<Asset>(`/api/v1/assets/${assetId}`, asset)
    return response.data
}

export const bulkFinalizeAssets = async (update: BulkUpdateAssetsDto) => {
    const response = await api.post(`/api/v1/assets/bulk-upload-finalize`, update)
    return response.data
}

export const getAsset = async (assetId: string) => {
    const response = await api.get<Asset>(`/api/v1/assets/${assetId}`)
    return response.data
}

export const deleteAsset = async (assetId: String) => {
    const response = await api.delete(`/api/v1/assets/${assetId}`)
    return response.data
}

export const requestChange = async(requestDto: RequestChangeDto) => {
    const response = await api.post<RequestChangeResult>(`/api/v1/change-requests`, requestDto)
    return response.data
}

export const giveFeedback = async(feedbackDto: CreateAssetFeedbackDto) => {
    const response = await api.post<AssetFeedbackDto>(`/api/v1/feedback`, feedbackDto)
    return response.data
}

export const flagAssetAsOutdated = async(assetId: string, flagAssetDto: FlagAssetDto) => {
    const response = await api.post<AssetFeedbackDto>(`/api/v1/assets/${assetId}/flag`, flagAssetDto)
    return response.data
}

export const requestNewAsset = async(requestDto: RequestNewAssetDto) => {
    const response = await api.post(`/api/v1/asset-requests`, requestDto)
    return response.data
}

export const getAssetTaskStatus = async(assetId: string) => {
    const response = await api.get<AssetTasksResponseDto>(`/api/v1/assets/${assetId}/tasks`)
    return response.data
}

export const retryTasks = async (assetId: string) => {
    const response = await api.post<AssetTasksResponseDto>(`/api/v1/assets/${assetId}/tasks`)
    return response.data
}

export const overrideAssetPreview = async(assetId: string, file: File) => {
    const formData = new FormData()
    formData.append("file", file)
    const response = await api.post<AssetPreviews>(`/api/v1/assets/${assetId}/previews/override`, formData, {
        headers: {"Content-Type": "multipart/form-data"},
    })
    return response.data
}

export const uploadNewFileVersion = async(assetId: string, file: File, dto: NewVersionDto) => {
    const formData = new FormData()
    formData.append("file", file)
    formData.append("dto", JSON.stringify(dto))
    const response = await api.put<Asset>(`/api/v1/assets/${assetId}/binary`, formData, {
        headers: {"Content-Type": "multipart/form-data"},
    })
    return response.data
}

export const setIsFavorite = async(assetId: string, isFavorite: boolean) => {
    const response = await api.put<AssetFavoriteResponseDto>(`/api/v1/assets/${assetId}/favorite`, { isFavorite })
    return response.data
}

export const bulkDeleteAssets = async(assetIds: string[]) => {
    const response = await api.post<AssetBulkDeleteResponseDto>(`/api/v1/assets/bulk/delete`, { assetIds })
    return response.data
}

export const getAssetBulkTagState = async (assetIds: string[]) => {
    const payload = { assetIds }
    const response = await api.post<AssetBulkTagStateResponseDto>(`/api/v1/assets/bulk/tags/get-state`, payload)
    return response.data
}

export const bulkUpdateAssetTags = async(
  assetIds: string[],
  selectedCategoryValueIds: string[],
  preservedCategoryValueIds: string[],
) => {
    const payload = { assetIds, selectedCategoryValueIds, preservedCategoryValueIds }
    const response = await api.put<AssetBulkUpdateTagsResponseDto>(`/api/v1/assets/bulk/tags`, payload)
    return response.data
}