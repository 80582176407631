import { useTheme } from '@mui/material/styles'
import { Avatar, Stack, Typography } from '@mui/material'
import Iconify from '../../Iconify.tsx'
import { AVATAR_SIZE, ICON_SIZE } from './UserSearchListSelect.tsx'
import { useUserSearchListSelectContext } from './UserSearchListSelectContext.tsx'

export function NoUserMatchesItem() {
  const { searchTerm } = useUserSearchListSelectContext()
  const theme = useTheme()
  return (
    <Stack direction='row' spacing={2} alignItems='center' p={2}>
      <Avatar sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE, bgcolor: theme.palette.bg.warmGrey2 }}>
        <Iconify icon='eva:person-outline' sx={{ height: ICON_SIZE, width: ICON_SIZE }} />
      </Avatar>
      <Typography>No matches for "{searchTerm}"</Typography>
    </Stack>
  )
}
