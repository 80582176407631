import { User } from '../../../@types/user.ts'
import { useTheme } from '@mui/material/styles'
import { Avatar, Stack, Typography } from '@mui/material'
import { AVATAR_SIZE } from './UserSearchListSelect.tsx'
import { useUserSearchListSelectContext } from './UserSearchListSelectContext.tsx'
import Iconify from '../../Iconify.tsx'
import { HoverableItem } from '../../admin/groups/field/HoverableItem.tsx'

export function UserEntry({user}: { user: User }){
  const theme = useTheme()
  const { deselectUser } = useUserSearchListSelectContext()

  // handlers
  const handleRemove = () => deselectUser(user.userId)
  const initials = user.firstName.charAt(0)
  return (
    <HoverableItem>
      <Stack direction='row' alignItems='center' justifyContent='space-between' >
        <Stack direction='row' spacing={2} alignItems='center'>
          <Avatar
            sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE, bgcolor: theme.palette.bg.mintDark }}>{initials}</Avatar>
          <Stack direction='column'>
            <Typography>{user.firstName} {user.lastName}</Typography>
            <Typography component='span' variant='small'>{user.email}</Typography>
          </Stack>
        </Stack>
        <Iconify
          icon='eva:close-outline'
          onClick={handleRemove}
          sx={{ cursor: 'pointer' }}
        />
      </Stack>
    </HoverableItem>
  )
}