import { Asset } from '../../../../../@types/asset'
import { TimeRange } from '../../../../../@types/analytics/analytics-common'
import RankWidget from '../common/RankWidget'
import { useShareRank } from '../../../../../hooks/analytics/useAssetShareAnalytics'
import { AudienceType } from '../../../../../@types/analytics/analytics-asset.ts'

export default function ShareRank({ asset, range, audience }: {
  asset: Asset,
  range: TimeRange,
  audience: AudienceType
}) {
  const { data, isLoading } = useShareRank(asset.assetId, { range, audience })

  if (isLoading) {
    return <></>
  }

  return (
    <RankWidget rank={data?.rank || 0} total={data?.maxRank || 0} />
  )
}