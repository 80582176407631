import { Asset } from '../../../../../@types/asset'
import TopUsers, { NO_LIMIT } from '../common/TopUsers'
import { TimeRange } from '../../../../../@types/analytics/analytics-common'
import { useState } from 'react'
import AllTopUsersDialog from '../common/AllTopUsersDialog'
import { TopDownloader } from '../../../../../@types/analytics/downloads'
import { useTopDownloaders } from '../../../../../hooks/analytics/useAssetDownloadAnalytics'
import { AudienceType } from '../../../../../@types/analytics/analytics-asset.ts'

export default function TopDownloaders({ asset, range, audience }: {
  asset: Asset,
  range: TimeRange,
  audience: AudienceType
}) {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { data } = useTopDownloaders(asset.assetId, 5, { range, audience })
  const { data: allDownloadersData } = useTopDownloaders(asset.assetId, NO_LIMIT, { range, audience }, modalOpen)
  const allDownloaders = allDownloadersData?.downloaders.map(toUserListItem)
  const topDownloaders = data?.downloaders.map(toUserListItem)
  return (
    <>
      <TopUsers
        title='Top Downloaders'
        list={topDownloaders}
        moreResults={data?.hasMore}
        onSeeAllClick={() => setModalOpen(true)}
      />

      <AllTopUsersDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        users={allDownloaders}
      />
    </>
  )
}

function toUserListItem(it: TopDownloader) {
  return {
    userId: it.userId,
    firstName: it.firstName,
    lastName: it.lastName,
    contentRoomId: it.contentRoomId,
    contentRoomName: it.contentRoomName,
    metricValue: it.numDownloads,
    metricLabel: it.numDownloads === 1 ? 'download' : 'downloads',
    metricIcon: 'eva:download-fill',
  }
}