import { Rank, TimeRange } from '../@types/analytics/analytics-common'
import api from '@api'
import { BucketedViews, TopViewers, TotalViews } from '../@types/analytics/views'
import { AudienceType } from '../@types/analytics/analytics-asset.ts'

export async function getTopViewers(assetId: string, options: {
  range: TimeRange,
  audience: AudienceType
}, limit: number = 5) {
  const params = { limit, start: options.range?.start, end: options.range?.end, audience: options.audience }
  const response = await api.get<TopViewers>(`/api/v1/analytics/assets/${assetId}/views/top-users`, { params })
  return response.data
}

export async function getBucketedViews(assetId: string, options: { range: TimeRange, audience: AudienceType }) {
  const params = { start: options.range?.start, end: options.range?.end, audience: options.audience }
  const response = await api.get<BucketedViews>(`/api/v1/analytics/assets/${assetId}/views/bucketed`, { params })
  return response.data
}

export async function getViewRank(assetId: string, options: { range: TimeRange, audience: AudienceType }) {
  const params = { start: options.range?.start, end: options.range?.end, audience: options.audience }
  const response = await api.get<Rank>(`/api/v1/analytics/assets/${assetId}/views/rank`, { params })
  return response.data
}

export async function getTotalViews(assetId: string, options: { range: TimeRange, audience: AudienceType }) {
  const params = { start: options.range?.start, end: options.range?.end, audience: options.audience }
  const response = await api.get<TotalViews>(`/api/v1/analytics/assets/${assetId}/views/total`, { params })
  return response.data
}