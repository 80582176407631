import { Rank, TimeRange } from '../@types/analytics/analytics-common'
import api from '@api'
import { BucketedShares, TopSharers, TotalShares } from '../@types/analytics/shares'
import { AudienceType } from '../@types/analytics/analytics-asset.ts'

export async function getTopSharers(assetId: string, options: {
  range: TimeRange,
  audience: AudienceType
}, limit: number = 5) {
  const params = { limit, start: options.range?.start, end: options.range?.end, audience: options.audience }
  const response = await api.get<TopSharers>(`/api/v1/analytics/assets/${assetId}/shares/top-users`, { params })
  return response.data
}

export async function getBucketedShares(assetId: string, options: { range: TimeRange, audience: AudienceType }) {
  const params = { start: options.range?.start, end: options.range?.end, audience: options.audience }
  const response = await api.get<BucketedShares>(`/api/v1/analytics/assets/${assetId}/shares/bucketed`, { params })
  return response.data
}

export async function getShareRank(assetId: string, options: { range: TimeRange, audience: AudienceType }) {
  const params = { start: options.range?.start, end: options.range?.end, audience: options.audience }
  const response = await api.get<Rank>(`/api/v1/analytics/assets/${assetId}/shares/rank`, { params })
  return response.data
}

export async function getTotalShares(assetId: string, options: { range: TimeRange, audience: AudienceType }) {
  const params = { start: options.range?.start, end: options.range?.end, audience: options.audience }
  const response = await api.get<TotalShares>(`/api/v1/analytics/assets/${assetId}/shares/total`, { params })
  return response.data
}