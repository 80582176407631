import TopUsers from '../common/TopUsers'
import { Asset } from '../../../../../@types/asset'
import { TimeRange } from '../../../../../@types/analytics/analytics-common'
import { useState } from 'react'
import AllTopUsersDialog from '../common/AllTopUsersDialog'
import { TopViewer } from '../../../../../@types/analytics/views'
import { useTopViewers } from '../../../../../hooks/analytics/useAssetViewAnalytics'
import { AudienceType } from '../../../../../@types/analytics/analytics-asset.ts'

export default function TopViewers({ asset, range, audience }: {
  asset: Asset,
  range: TimeRange,
  audience: AudienceType
}) {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { data } = useTopViewers(asset.assetId, 5, { range, audience })
  const { data: allViewersData } = useTopViewers(asset.assetId, 5, { range, audience }, modalOpen)
  const allViewers = allViewersData?.viewers.map(toUserListItem)
  const viewers = data?.viewers.map(toUserListItem)
  return (
    <>
      <TopUsers
        title='Top Viewers'
        list={viewers}
        moreResults={data?.hasMore}
        onSeeAllClick={() => setModalOpen(true)}
      />

      <AllTopUsersDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        users={allViewers}
      />

    </>

  )
}

function toUserListItem(it: TopViewer) {
  return {
    userId: it.userId,
    firstName: it.firstName,
    lastName: it.lastName,
    contentRoomId: it.contentRoomId,
    contentRoomName: it.contentRoomName,
    metricValue: it.numViews,
    metricLabel: it.numViews === 1 ? 'view' : 'views',
    metricIcon: 'eva:search-fill',
  }
}