export enum UserType {
    ADMIN = 1,
    CREATOR = 2,
    VIEWER = 3
}

export enum UserStatus {
    ACTIVE = 1,
    INVITED = 2
}

export const USER_TYPE_DISPLAY_VALUE = {
    [UserType.ADMIN] : "Administrator",
    [UserType.CREATOR] : "Creator",
    [UserType.VIEWER] : "Searcher"
} as Record<UserType, string>

export type CreateUserDto = {
    firstName: string,
    lastName: string,
    email: string,
    userType: UserType
}
export type UpdateUserDto = CreateUserDto

export type User = {
    userId: string,
    firstName: string,
    lastName: string,
    email: string,
    enabled: boolean,
    userType: UserType,
    status: UserStatus,
    acceptedTermsOfServiceAt: string | null
}

export type UserSearchResponse = {
    users: User[]
    offset: number
    limit: number
    totalResultCount: number
}

export type UserBatchLookupResponse = {
    users: User[]
}

export enum UserSortBy {
    NAME = 1,
    EMAIL = 2,
    ROLE = 3,
    STATUS = 4
}