import { alpha, useTheme } from '@mui/material/styles'
import { Box, InputAdornment, TextField } from '@mui/material'
import Iconify from '../../Iconify.tsx'
import { CurrentlySelectedUsers } from './CurrentlySelectedUsers.tsx'
import { UserSearchResults } from './UserSearchResults.tsx'
import { UserSearchListSelectContextProvider, useUserSearchListSelectContext } from './UserSearchListSelectContext.tsx'

const SEARCH_CONTENT_HEIGHT = 350
export const AVATAR_SIZE = 40
export const ICON_SIZE = AVATAR_SIZE / 2

type UserSelectorProps = {
  defaultUserIds?: string[],
  onChange?: (userIds: string[]) => void
}

/**
 * A user-selection component optimized for selecting multiple users
 * and displaying the currently selected items.
 *
 * This component serves different purposes than the UserAutocomplete. Whereas
 * the UserAutocomplete serves to simply search -> select users, this component
 * allows you to search both selected users and non selected users.
 *
 * It will filter both the selected users and the non-selected users to the
 * search term.
 */
export function UserSearchListSelect({ defaultUserIds, onChange }: UserSelectorProps) {
  const theme = useTheme()

  return (
    <UserSearchListSelectContextProvider
      defaultSelectedUserIds={defaultUserIds}
      onSelectedUsersChange={onChange}
    >
      <Box sx={{
        border: `1px solid ${alpha(theme.palette.text.deemphasized, .25)}`,
        borderRadius: theme.spacing(1),
        minWidth: 350,
      }}>
        <UserSearchBox />
        <UserSearchResultContent />
      </Box>
    </UserSearchListSelectContextProvider>
  )
}

function UserSearchBox() {
  const { searchTerm, setSearchTerm } = useUserSearchListSelectContext()
  const handleSearchTermClear = () => setSearchTerm('')
  return (
    <Box sx={{
      padding: 2,
    }}>
      <TextField
        fullWidth
        size='small'
        value={searchTerm}
        onChange={(ev) => setSearchTerm(ev.target.value)}
        placeholder='Search users...'
        InputProps={{
          autoFocus: true,
          startAdornment: (
            <InputAdornment position='start'>
              <Iconify icon='eva:search-outline' />
            </InputAdornment>
          ),
          endAdornment: !searchTerm ? undefined : (
            <InputAdornment position='end'>
              <Iconify icon='eva:close-outline' sx={{ cursor: 'pointer' }} onClick={handleSearchTermClear} />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}

function UserSearchResultContent() {
  const { searchTerm } = useUserSearchListSelectContext()
  const isSearching = Boolean(searchTerm)
  return (
    <Box
      sx={{
        height: SEARCH_CONTENT_HEIGHT,
        overflow: 'scroll',
      }}
    >
      {!isSearching && <CurrentlySelectedUsers />}
      {isSearching && <UserSearchResults />}
    </Box>
  )
}