import { useMemo } from 'react'
import keyBy from 'lodash/keyBy'
import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { UserToAddItem } from './UserToAddItem'
import { NoUserMatchesItem } from './NoUserMatchesItem.tsx'
import { useUserSearch } from '../../../hooks/useUsers.ts'
import { useUserSearchListSelectContext } from './UserSearchListSelectContext.tsx'

export function NotSelectedUserList() {
  const { selectedUsers, searchTerm } = useUserSearchListSelectContext()
  const { data, isFetching } = useUserSearch(searchTerm, 100)
  const selectedUsersById = useMemo(() => keyBy(selectedUsers, 'userId'), [selectedUsers])
  const matchingUsers = data?.users || []
  const searchedUsersNotSelected = matchingUsers.filter(it => !selectedUsersById[it.userId]).slice(0, 15)
  return (
    <Box>
      <Box px={2}><Typography variant='smallHighlight'>Not selected</Typography></Box>
      {isFetching && <Box py={2} px={3.5}><CircularProgress size={16} /></Box> }
      {!isFetching && (
        <Stack>
          {searchedUsersNotSelected.map(it => <UserToAddItem key={it.userId} user={it} />)}
        </Stack>
      )}
      {!isFetching && searchedUsersNotSelected.length === 0 && <NoUserMatchesItem />}
    </Box>
  )
}


