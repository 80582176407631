import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { useUsers } from '../../../hooks/useUsers.ts'
import { User } from '../../../@types/user.ts'

type ContextState = {
  selectedUserIds: string[]
  selectedUsers: User[]
  searchTerm: string
}

type ActionCreators = {
  selectUser: (userId: string) => void
  deselectUser: (userId: string) => void
  setSearchTerm: (term: string) => void
}

type UserSearchListSelectContextType = ContextState & ActionCreators
const UserSearchListContext = createContext<UserSearchListSelectContextType>({} as UserSearchListSelectContextType)

type ProviderProps = {
  defaultSelectedUserIds?: string[],
  onSelectedUsersChange?: (userIds: string[]) => void
  children: ReactNode
}

const Provider = ({ defaultSelectedUserIds, onSelectedUsersChange, children }: ProviderProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>(defaultSelectedUserIds || [])
  const { data: selectedUsers } = useUsers(selectedUserIds)

  const selectUser = async (userId: string) => setSelectedUserIds((prev) => [...prev, userId])
  const deselectUser = async (userId: string) => setSelectedUserIds((prev) => prev.filter(it => it !== userId))

  useEffect(() => {
    onSelectedUsersChange && onSelectedUsersChange(selectedUserIds)
  }, [selectedUserIds])

  return (
    <UserSearchListContext.Provider
      value={{
        selectUser,
        deselectUser,
        setSearchTerm,
        searchTerm,
        selectedUserIds,
        selectedUsers,
      }}>
      {children}
    </UserSearchListContext.Provider>
  )
}

export { Provider as UserSearchListSelectContextProvider, UserSearchListContext }

export function useUserSearchListSelectContext() {
  return useContext<UserSearchListSelectContextType>(UserSearchListContext)
}