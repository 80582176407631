import { User } from '../../../@types/user.ts'
import { useTheme } from '@mui/material/styles'
import { HoverableItem } from '../../admin/groups/field/HoverableItem.tsx'
import { Avatar, Box, Button, Stack, Typography } from '@mui/material'
import { AVATAR_SIZE } from './UserSearchListSelect.tsx'
import { useUserSearchListSelectContext } from './UserSearchListSelectContext.tsx'

export function UserToAddItem({ user }: { user: User }) {
  const theme = useTheme()
  const { selectUser } = useUserSearchListSelectContext()

  // handlers
  const handleAdd = () => selectUser(user.userId)

  // calculated props
  const name = `${user.firstName} ${user.lastName}`
  const initials = name.charAt(0)

  return (
    <HoverableItem>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack direction='row' spacing={2} alignItems='center'>
          <Avatar
            sx={{ height: AVATAR_SIZE, width: AVATAR_SIZE, bgcolor: theme.palette.bg.mintDark }}>{initials}</Avatar>
          <Stack direction='column'>
            <Typography>{name}</Typography>
            <Typography component='span' variant='small'>{user.email}</Typography>
          </Stack>
        </Stack>
        <Box>
          <Button
            size='small'
            variant='outlined'
            onClick={handleAdd}
          >
            Add
          </Button>
        </Box>
      </Stack>
    </HoverableItem>
  )
}
