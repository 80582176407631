import { Stack } from '@mui/material'
import { FilteredSelectedUserList } from './FilteredSelectedUserList.tsx'
import { NotSelectedUserList } from './NotSelectedUserList.tsx'

export function UserSearchResults() {
  return (
    <Stack>
      <FilteredSelectedUserList  />
      <NotSelectedUserList />
    </Stack>
  )
}
