import { addDays, addMonths, addYears, differenceInDays, endOfHour, getYear, startOfDay, startOfMonth } from 'date-fns'

export enum AnalyticsInterval {
  LAST_30_DAYS = 1,
  LAST_12_MONTHS = 2
}

export type TimeRange = {
  start?: Date | null,
  end?: Date | null
}

export type BasicUserInfo = {
  userId: string | null,
  firstName: string | null,
  lastName: string | null,
}

export type BasicContentRoomInfo = {
  contentRoomId: string | null,
  contentRoomName: string | null
}

export type Rank = {
  interval: AnalyticsInterval,
  maxRank: number,
  rank?: number | null
}

export function timeRangeForInterval(interval: AnalyticsInterval) {
  if (interval == AnalyticsInterval.LAST_30_DAYS) {
    return {
      start: startOfDay(addDays(new Date(), -30)),
      end: endOfHour(new Date()),
    }
  }

  if (interval == AnalyticsInterval.LAST_12_MONTHS) {
    return {
      start: startOfMonth(addMonths(new Date(), -12)),
      end: endOfHour(new Date()),
    }
  }

  return {}
}

export function dateFormatForTimeRange(range: TimeRange){
  const start = range.start || addYears(new Date(), -5)
  const end = range.end || new Date()
  const dateDiff = differenceInDays(start, end)

  const isMonths = Math.abs(dateDiff) > 210
  const shouldIncludeYear = getYear(start) !== getYear(end)

  if(isMonths && shouldIncludeYear){
    return "MMM 'YY"
  }

  return isMonths ? "MMM" : "MMM D"
}