import { User } from './user'
import { GeneralAccessType, ShareLevel } from './sharing'

export type Company = {
  companyId: string,
  name: string,
  subdomain: string
}

export type BootstrapResponse = {
  user: User,
  companies: Company[],
  activeCompanyId: string | null,
  settings: BootstrapSettings,
  enabledFeatures: string[],
  sharingBaseUrl: string
}

export type BootstrapSettings = {
  defaultAssetGeneralAccessType: GeneralAccessType
  defaultAssetShareLevel: ShareLevel,
  scimEnabled: boolean,
  usernamePasswordEnabled: boolean,
  assetRequestMode: AssetRequestMode,
  assetRequestExternalLink: string | null
}

export type LoginResponse = {
  message: string | null,
  success: boolean
}

export type DiscoveryResponse = {
  useSAML: boolean,
  useGoogleSocial: boolean,
  useUsernamePassword: boolean,
}

export type SAMLRedirectDiscoveryResponse = {
  samlRedirectUrl: string | null
}

export type AcceptTermsOfServiceResponse = {
  acceptedTermsOfServiceAt: string | null
}

export enum AssetRequestMode {
  MASSET_SYSTEM = 1,
  DISABLED = 2,
  EXTERNAL_LINK = 3
}