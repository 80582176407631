import { Box, Stack, Typography } from '@mui/material'
import { UserEntry } from './UserEntry.tsx'
import { useUserSearchListSelectContext } from './UserSearchListSelectContext.tsx'
import EmptyContent from '../../EmptyContent.tsx'

export function CurrentlySelectedUsers() {
  const { selectedUsers } = useUserSearchListSelectContext()
  return (
    <Stack>
      {selectedUsers.length != 0 &&
        <Box px={2}><Typography variant='smallHighlight'>Currently Selected</Typography></Box>
      }
      <Box>
        {selectedUsers.length == 0 && <NoSelections />}
        {selectedUsers?.map(it => {
          return <UserEntry key={it.userId} user={it} />
        })}
      </Box>
    </Stack>
  )
}

function NoSelections() {
  return (
    <Box sx={{ maxWidth: 200, margin: '0 auto' }}>
      <EmptyContent
        imageHeight={75}
        title='No users selected'
        description='Add/Search for users using the filter above'
      />
    </Box>
  )
}