import { Asset } from '../../../../../@types/asset'
import { dateFormatForTimeRange, TimeRange } from '../../../../../@types/analytics/analytics-common'
import TrendGraph from '../common/TrendGraph'
import moment from 'moment-timezone'
import { useBucketeShares } from '../../../../../hooks/analytics/useAssetShareAnalytics'
import { AudienceType } from '../../../../../@types/analytics/analytics-asset.ts'

export default function BucketedShares({ asset, range, audience }: { asset: Asset, range: TimeRange, audience: AudienceType }) {
  const { data } = useBucketeShares(asset.assetId, { range, audience })
  const seriesData = data?.buckets?.map(it => ({ date: moment.utc(it.bucket), y: it.numShares })) || []
  const series = { name: 'Shares', data: seriesData }
  const dateFormat = dateFormatForTimeRange(range)
  return (
    <TrendGraph title='Shares Over Time' series={series} dateFormat={dateFormat} />
  )
}