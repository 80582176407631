import { useState, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import LoadingScreen from '../components/LoadingScreen';
import { TermsOfServiceAcceptance } from '../pages/auth/TermsOfServiceAcceptance.tsx'

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isLoading, user } = useAuth();
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const [requestedLocationState, setRequestedLocationState] = useState<string | null>(null);
  const requestedLocationLocalStorage = window.localStorage.getItem("requestedLocation")
  const requestedLocation = requestedLocationState || requestedLocationLocalStorage

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocationState(pathname);
    }
    return <Login />;
  }

  if(user?.acceptedTermsOfServiceAt == null){
    if (pathname !== requestedLocation) {
      setRequestedLocationState(pathname);
    }
    return <TermsOfServiceAcceptance />
  }

  if (requestedLocation && pathname !== requestedLocation) {
    // there is a weird timing issue here. This is a hack to get it working for now.
    setTimeout(() => {
      setRequestedLocationState(null);
      window.localStorage.setItem("requestedLocation", "");
      navigate(requestedLocation)
    }, 1)
    return <LoadingScreen />
  }

  return <>{children}</>;
}
