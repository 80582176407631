import { TimeRange } from '../../@types/analytics/analytics-common'
import { useQuery } from '@tanstack/react-query'
import {
  getBucketedDownloads,
  getDownloadRank,
  getTopDownloaders,
  getTotalDownloads,
} from '../../clients/AssetAnalyticDownloadClient'
import { AudienceType } from '../../@types/analytics/analytics-asset.ts'

export function useAssetTotalDownloads(assetId: string, options: { range: TimeRange, audience: AudienceType }) {
  return useQuery({
    queryKey: ['total-downloads', assetId, options],
    queryFn: async () => getTotalDownloads(assetId, options),
    refetchOnWindowFocus: false,
  })
}

export function useDownloadRank(assetId: string, options: { range: TimeRange, audience: AudienceType }) {
  return useQuery({
    queryKey: ['download-rank', assetId, options],
    queryFn: async () => getDownloadRank(assetId, options),
    refetchOnWindowFocus: false,
  })
}

export function useBucketedDownloads(assetId: string, options: { range: TimeRange, audience: AudienceType }) {
  return useQuery({
    queryKey: ['bucketed-downloads', assetId, options],
    queryFn: async () => getBucketedDownloads(assetId, options),
    refetchOnWindowFocus: false,
  })
}

export function useTopDownloaders(assetId: string, limit: number, options: {
  range: TimeRange,
  audience: AudienceType
}, enabled: boolean = true) {
  return useQuery({
    queryKey: ['top-downloaders', assetId, limit, options],
    queryFn: async () => getTopDownloaders(assetId, options, limit),
    refetchOnWindowFocus: false,
    enabled: enabled,
  })
}