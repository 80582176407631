import { Asset } from '../../../../../@types/asset'
import { TimeRange } from '../../../../../@types/analytics/analytics-common'
import TotalWidget from '../common/TotalWidget'
import ViewIllustration from '../../../../../assets/illustration_view'
import { useAssetTotalViews } from '../../../../../hooks/analytics/useAssetViewAnalytics'
import { AudienceType } from '../../../../../@types/analytics/analytics-asset.ts'

export default function TotalViews({ asset, range, audience }: { asset: Asset, range: TimeRange, audience: AudienceType }) {
  const { data, isLoading } = useAssetTotalViews(asset.assetId, { range, audience })

  if(isLoading){
    return <></>
  }

  return (
    <TotalWidget
      title="Total Views"
      total={data?.totalViews || 0}
      icon={<ViewIllustration />}
    />
  )
}