import { Asset } from '../../../../@types/asset'
import { TimeRange } from '../../../../@types/analytics/analytics-common'
import BucketedShares from './share/BucketedShares'
import TopSharers from './share/TopSharers'
import { Grid } from '@mui/material'
import ShareRank from './share/ShareRank'
import TotalShares from './share/TotalShares'
import { AudienceType } from '../../../../@types/analytics/analytics-asset.ts'

export default function ShareMetricsAssetDetailsLayout({ asset, range, audience }: { asset: Asset, range: TimeRange, audience: AudienceType }) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <TotalShares asset={asset} range={range} audience={audience} />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <ShareRank asset={asset} range={range} audience={audience} />
      </Grid>
      <Grid item xs={12}>
        <BucketedShares asset={asset} range={range} audience={audience} />
      </Grid>
      <Grid item xs={12}>
        <TopSharers asset={asset} range={range} audience={audience} />
      </Grid>
    </Grid>
  );
}
