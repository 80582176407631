import { DEFAULT_FILTERS, PREFS_SEARCH_PAGE_SIZE_KEY, SearchFilters } from '../SearchContext'
import { setFiltersToQueryParams, useSearchParamsAsFilters } from "./searchQueryParamHelpers";
import { useSessionStorage } from "usehooks-ts";
import { useLocation } from "react-router-dom";


export function useStoredSearchFilters(){
  const { state : locationState } = useLocation()
  const [sessionFilters, setSessionFilters] = useSessionStorage<SearchFilters>("search-filters", DEFAULT_FILTERS)
  const queryParamFilters = useSearchParamsAsFilters()

  const storeFilters = (filters: SearchFilters) => {
    setSessionFilters(filters)
    setFiltersToQueryParams(filters)
  }

  // local storage preferences
  const pageSizePreference = window.localStorage.getItem(PREFS_SEARCH_PAGE_SIZE_KEY)
  const localStoragePrefs = pageSizePreference ? { pageSize: parseInt(pageSizePreference, 10) } : {}

  // prev session search (if preserved)
  const preservedFilters = (locationState as any)?.preservePrevSearch ? sessionFilters : {}

  // default search state is calculated as such:
  // 1. loaded from local storage preferences
  // 2. loaded from prev session search (if preserved)
  // 3. overridden by query params if specified
  const filters = {
    ...DEFAULT_FILTERS,
    ...localStoragePrefs,
    ...preservedFilters,
    ...queryParamFilters
  }

  return {
    filters,
    storeFilters
  }
}