import { TimeRange } from '../../@types/analytics/analytics-common'
import { useQuery } from '@tanstack/react-query'
import { getBucketedViews, getTopViewers, getTotalViews, getViewRank } from '../../clients/AssetAnalyticViewClient'
import { AudienceType } from '../../@types/analytics/analytics-asset.ts'

export function useAssetTotalViews(assetId: string, options: { range: TimeRange, audience: AudienceType }) {
  return useQuery({
    queryKey: ['total-views', assetId, options],
    queryFn: async () => getTotalViews(assetId, options),
    refetchOnWindowFocus: false,
  })
}

export function useViewRank(assetId: string, options: { range: TimeRange, audience: AudienceType }) {
  return useQuery({
    queryKey: ["view-rank", assetId, options],
    queryFn: async () => getViewRank(assetId, options),
    refetchOnWindowFocus: false,
  });
}

export function useBucketedViews(assetId: string, options: { range: TimeRange, audience: AudienceType }){
  return useQuery({
    queryKey: ['bucketed-views', assetId, options],
    queryFn: async () => getBucketedViews(assetId, options),
    refetchOnWindowFocus: false,
  })
}

export function useTopViewers(assetId: string, limit: number, options: { range: TimeRange, audience: AudienceType }, enabled: boolean = true){
  return useQuery({
    queryKey: ['top-viewers', assetId, limit, options],
    queryFn: async () => getTopViewers(assetId, options, limit),
    refetchOnWindowFocus: false,
    enabled: enabled
  })
}