import { useMemo } from 'react'
import { Box, Stack, Typography } from '@mui/material'
import { UserEntry } from './UserEntry.tsx'
import { NoUserMatchesItem } from './NoUserMatchesItem.tsx'
import { useUserSearchListSelectContext } from './UserSearchListSelectContext.tsx'

export function FilteredSelectedUserList() {
  const { selectedUsers, searchTerm } = useUserSearchListSelectContext()

  const filteredUsers = useMemo(() => {
    const toMatch = searchTerm.toLowerCase().trim()
    return selectedUsers.filter(
      it => it.email.toLowerCase().includes(toMatch) ||
        it.firstName.toLowerCase().includes(toMatch) ||
        it.lastName.toLowerCase().includes(toMatch)
    )
  }, [selectedUsers, searchTerm])
  return (
    <Box>
      <Box px={2}><Typography variant='smallHighlight'>Currently Selected</Typography></Box>
      <Stack>
        {filteredUsers.map(it => <UserEntry key={it.userId} user={it} />)}
      </Stack>
      {filteredUsers.length === 0 && <NoUserMatchesItem />}
    </Box>
  )
}
