import { ElementType, lazy, Suspense } from 'react'
import { Navigate, Outlet, useLocation, useRoutes } from 'react-router-dom'
// layouts
import LogoOnlyLayout from '../layouts/LogoOnlyLayout'
// components
import LoadingScreen from '../components/LoadingScreen'
import Login from '../pages/auth/Login'
import AuthGuard from '../guards/AuthGuard'
import GuestGuard from '../guards/GuestGuard'
import CompanyGuard from '../guards/CompanyGuard'
import { CompanySelect } from '../pages/auth/CompanySelect'
import AssetUpload from '../pages/AssetUpload'
import AssetUploadFinalize from '../pages/AssetUploadFinalize'
import AssetLayout from '../layouts/asset'
import AssetSearch from '../pages/AssetSearch'
import { AssetDetail } from '../pages/AssetDetail'
import AdminUsers from '../pages/admin/Users'
import AdminBilling from '../pages/admin/Billing'
import AdminLayout from '../layouts/admin/AdminLayout'
import PageRoleGuard from '../guards/PageRoleGuard'
import { UserType } from '../@types/user'
import EditUser from '../pages/admin/EditUser'
import ProfileInfo from '../pages/profile/ProfileInfo'
import ProfileLayout from '../layouts/profile/ProfileLayout'
import ProfileSecurity from '../pages/profile/ProfileSecurity'
import ProfileAppearance from '../pages/profile/ProfileAppearance'
import AdminCompanySettings from '../pages/admin/AdminCompanySettings'
import AnalyticsExploration from '../pages/AnalyticsExploration'
import { AssetUploadProvider } from '../contexts/AssetUploadContext'
import AssetEditSingle from '../pages/asset/AssetEditSingle'
import { AssetProvider } from '../contexts/AssetContext'
import AcceptInvite from '../pages/auth/AcceptInvite'
import { BulkUsers } from '../pages/admin/BulkUsers'
import AdminInvitations from '../pages/admin/AdminInvitations'
import PasswordResetRequest from '../pages/auth/PasswordResetRequest'
import PasswordResetSubmit from '../pages/auth/PasswordResetSubmit'
import AdminCategories from '../pages/admin/AdminCategories'
import AnalyticsLayout from '../layouts/analytics'
import GlobalAssetAnalytics from '../pages/analytics/GlobalAssetAnalytics'
import { GlobalUserAnalytics } from '../pages/analytics/GlobalUserAnalytics'
import { AdminGroups } from '../pages/admin/Groups'
import EditGroup from '../pages/admin/EditGroup'
import NewGroup from '../pages/admin/NewGroup'
import ContentRoomsLayout from '../layouts/content-rooms/ContentRoomsLayout'
import { ContentRooms } from '../pages/content-rooms/ContentRooms'
import { CreateContentRoom } from '../pages/content-rooms/CreateContentRoom'
import { EditContentRoom } from '../pages/content-rooms/EditContentRoom'
import { HostedContentRoomGuard } from '../guards/HostedContentRoomGuard'
import { HostedContentRoomLayout } from '../layouts/hosted-content-room/HostedContentRoomLayout'
import SlackUserRegistration from '../pages/integrations/slack/SlackUserRegistration'
import { AssetDownload } from '../pages/AssetDownload'
import { SlackOauthCompletion } from '../pages/integrations/slack/SlackOauthCompletion'
import { SlackOauthCancellation } from '../pages/integrations/slack/SlackOauthCancellation'
import { OauthFailure } from '../pages/integrations/oauth/OauthFailure'
import { OauthSuccess } from '../pages/integrations/oauth/OauthSuccess'
import ProfileIntegrations from '../pages/profile/ProfileIntegrations'
import { ProfileIntegrationDetails } from '../pages/profile/ProfileIntegrationDetails'
import { ConversationsHomePage } from '../pages/conversations/ConversationsHomePage.tsx'
import { ConversationsLayout } from '../layouts/conversations/ConversationsLayout.tsx'
import { ContentAnalysis } from '../pages/admin/ContentAnalysis.tsx'
import { HostedContentRoomAssetPage } from '../pages/hosted-content-room/HostedContentRoomAssetPage.tsx'
import { ProfileNotifications } from '../pages/profile/ProfileNotifications.tsx'
import GlobalShareAnalytics from '../pages/analytics/GlobalShareAnalytics.tsx'
import AnalyticsIndex from '../pages/AnalyticsIndex.tsx'

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  )
}

// Pages
const ConversationPage = Loadable(lazy(() => import("../pages/conversations/ConversationPage.tsx")))

// ----------------------------------------------------------------------



export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to='/assets' replace />,
    },
    {
      path: '/login',
      element: <GuestGuard> <Login /> </GuestGuard>,
    },
    {
      path: '/login/companies',
      // todo: do we need something similar to the GuestGuard here for companies?
      element: <CompanySelect />,
    },
    {
      path: '/invite/:inviteToken',
      element: <GuestGuard> <AcceptInvite /> </GuestGuard>,
    },
    {
      path: '/password-reset',
      element: <GuestGuard> <PasswordResetRequest /> </GuestGuard>,
    },
    {
      path: '/password-reset/:resetToken',
      element: <GuestGuard> <PasswordResetSubmit /> </GuestGuard>,
    },
    {
      path: '/assets',
      element: (
        <AuthGuard> <CompanyGuard> <AssetLayout /> </CompanyGuard> </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/assets/search' replace />, index: true },

        // stage 1 - upload asset(s)
        {
          path: 'upload', element: (
            <AssetUploadProvider>
              <PageRoleGuard permittedRoles={[UserType.ADMIN, UserType.CREATOR]}>
                <AssetUpload />
              </PageRoleGuard>
            </AssetUploadProvider>
          ),
        },

        // stage 2 - assign metadata
        {
          path: 'upload/finalize', element: (
            <AssetUploadProvider>
              <PageRoleGuard permittedRoles={[UserType.ADMIN, UserType.CREATOR]}>
                <AssetUploadFinalize />
              </PageRoleGuard>
            </AssetUploadProvider>
          ),
        },

        // search page
        { path: 'search', element: <AssetSearch /> },

        // asset detail
        {
          path: 'detail/:assetId', element: (
            <AssetProvider>
              <AssetDetail />
            </AssetProvider>
          ),
        },

        // single asset edit page (we can possibly combine this with the asset upload finalize at some point)
        {
          path: 'edit/:assetId', element:
            <PageRoleGuard permittedRoles={[UserType.ADMIN, UserType.CREATOR]}>
              <AssetProvider>
                <AssetEditSingle />
              </AssetProvider>
            </PageRoleGuard>,
        },

        // asset auto-download
        {
          path: 'detail/:assetId/download',
          element: <AssetDownload />,
        },
      ],
    },
    // conversations page
    {
      path: '/conversations',
      element: (
        <AuthGuard>
          <ConversationsLayout>
            <Outlet />
          </ConversationsLayout>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/conversations/home' replace />, index: true },
        { path: 'home', element: <ConversationsHomePage /> },
        { path: ':conversationId', element: <ConversationPage /> },
      ],
    },
    {
      path: '/profile',
      element:
        (
          <AuthGuard>
            <ProfileLayout />
          </AuthGuard>
        ),
      children: [
        { element: <Navigate to='/profile/info' replace />, index: true },
        { path: 'info', element: <ProfileInfo /> },
        { path: 'appearance', element: <ProfileAppearance /> },
        { path: 'security', element: <ProfileSecurity /> },
        { path: 'notifications', element: <ProfileNotifications /> },
        {
          path: 'integrations',
          children: [
            { element: <Navigate to='/profile/integrations/list' replace />, index: true },
            { path: 'list', element: <ProfileIntegrations /> },
            { path: 'details/:accountId', element: <ProfileIntegrationDetails /> },
          ],
        },
      ],
    },
    {
      path: '/content-rooms',
      element: (
        <AuthGuard>
          <CompanyGuard>
            <ContentRoomsLayout />
          </CompanyGuard>
        </AuthGuard>
      ),
      children: [
        { element: <ContentRooms />, index: true },
        { path: 'new', element: <CreateContentRoom /> },
        { path: ':contentRoomId', element: <EditContentRoom /> },
      ],
    },
    {
      path: '/analytics',
      element: (
        <AuthGuard>
          <CompanyGuard>
            <AnalyticsLayout />
          </CompanyGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/analytics/explore' replace />, index: true },
        {
          path: 'explore',
          element: <AnalyticsExploration />,
          children: [
            { element: <AnalyticsIndex />, index: true },
            { path: 'shares', element: <GlobalShareAnalytics /> },
            { path: 'assets', element: <GlobalAssetAnalytics /> },
            { path: 'users', element: (
                <PageRoleGuard permittedRoles={[UserType.ADMIN]}>
                  <GlobalUserAnalytics />
                </PageRoleGuard>
            )},
          ],
        },
      ],
    },
    {
      path: '/admin',
      element: (
        <AuthGuard>
          <CompanyGuard>
            <PageRoleGuard permittedRoles={[UserType.ADMIN]}>
              <AdminLayout />
            </PageRoleGuard>
          </CompanyGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to='/admin/users' replace />, index: true },
        { path: 'users', element: <AdminUsers /> },
        { path: 'users/:userId', element: <EditUser /> },
        { path: 'users/bulk', element: <BulkUsers /> },
        { path: 'groups', element: <AdminGroups /> },
        { path: 'groups/new', element: <NewGroup /> },
        { path: 'groups/:groupId', element: <EditGroup /> },
        { path: 'invitations', element: <AdminInvitations /> },
        { path: 'categories', element: <AdminCategories /> },
        { path: 'billing', element: <AdminBilling /> },
        { path: 'company', element: <AdminCompanySettings /> },
        { path: 'content-audit', element: <ContentAnalysis /> },
      ],
    },
    {
      path: '/integrations/oauth',
      children: [
        {
          path: 'success-landing',
          element: (<OauthSuccess />),
        },
        {
          path: 'failure-landing',
          element: (<OauthFailure />),
        },
      ],
    },
    {
      path: '/integrations/slack',
      children: [
        {
          path: 'user-registration',
          element: (<AuthGuard> <SlackUserRegistration /> </AuthGuard>),
        },
        {
          path: 'oauth-completion',
          element: (<SlackOauthCompletion />),
        },
        {
          path: 'oauth-cancellation',
          element: (<SlackOauthCancellation />),
        },
      ],
    },
    {
      path: '/cr/:shortCode',
      element: (
        <HostedContentRoomGuard><HostedContentRoomLayout /></HostedContentRoomGuard>
      ),
      children: [
        // for now, anything but deep-links
        { element: <Navigate to='/404' replace />, index: true },
        // { path: 'explore', element: <HostedContentRoomLanding /> },
        { path: 'a/:assetShortCode', element: <HostedContentRoomAssetPage /> },
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to='/404' replace /> },
      ],
    },
    { path: '*', element: <Navigate to='/404' replace /> },
  ])
}

// Dashboard
// const PageOne = Loadable(lazy(() => import('../pages/PageOne')));
// const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
// const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
// const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
// const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
// const PageSix = Loadable(lazy(() => import('../pages/PageSix')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')))


