import { createContext, ReactNode } from 'react'
import { ActionMap } from '../@types/reducer.ts'
import { useImmerReducer } from 'use-immer'
import { RangeFilter } from './UserAnalyticsContext.tsx'
import { addDays, endOfToday, startOfDay } from 'date-fns'
import { AdminAnalyticsSortBy } from '../@types/analytics/analytics-asset.ts'

export type ShareAnalyticsState = {
  filters: ShareAnalyticsFilters
}

export type ShareAnalyticsFilters = {
  currentPage: number,
  pageSize: number,
  sortBy: AdminAnalyticsSortBy,
  sortDirection: 'asc' | 'desc',

  // we include a date filter by default for easier ux
  boundingStartDate: Date | null | undefined
  boundingEndDate: Date | null | undefined
  ownerIds: string[]
}

type ShareAnalyticsActionCreators = {
  setPage: (page: number) => Promise<void>
  setDateRange: (rangeFilter: RangeFilter) => Promise<void>
  setSort: (sortBy: AdminAnalyticsSortBy, direction: 'asc' | 'desc') => Promise<void>
  setOwners: (userIds: string[]) => Promise<void>
}

type ShareAnalyticsContextType = ShareAnalyticsState & ShareAnalyticsActionCreators
const ShareAnalyticsContext = createContext<ShareAnalyticsContextType>({} as ShareAnalyticsContextType)

enum ActionTypes {
  SetPage = 'SET_PAGE',
  SetDateRange = 'SET_DATE_RANGE',
  SetSort = 'SET_SORT',
  SetOwners = 'SET_OWNERS',
}

type Payload = {
  [ActionTypes.SetPage]: { pageNumber: number }
  [ActionTypes.SetDateRange]: { range: RangeFilter }
  [ActionTypes.SetSort]: { sortBy: AdminAnalyticsSortBy, sortDirection: 'asc' | 'desc' }
  [ActionTypes.SetOwners]: { ownerIds: string[] }
}

type Action = ActionMap<Payload>[keyof ActionMap<Payload>]

const Reducer = (state: ShareAnalyticsState, action: Action) => {
  switch (action.type){
    case ActionTypes.SetPage:
      state.filters.currentPage = action.payload.pageNumber
      return
    case ActionTypes.SetDateRange:
      state.filters.boundingStartDate = action.payload.range.startDate
      state.filters.boundingEndDate = action.payload.range.endDate
      state.filters.currentPage = 0
      return
    case ActionTypes.SetSort:
      state.filters.sortBy = action.payload.sortBy
      state.filters.sortDirection = action.payload.sortDirection
      state.filters.currentPage = 0
      return
    case ActionTypes.SetOwners:
      state.filters.ownerIds = action.payload.ownerIds
      state.filters.currentPage = 0
      return
    default:
      return
  }
}

const DEFAULT_INITIAL_STATE = {
  filters: {
    currentPage: 0,
    pageSize: 30,
    sortBy: AdminAnalyticsSortBy.NUM_OPENS,
    sortDirection: 'desc',

    // default filters
    boundingStartDate: startOfDay(addDays(new Date(), -30)),
    boundingEndDate: endOfToday(),
    ownerIds: []
  },
} as ShareAnalyticsState

const Provider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useImmerReducer(Reducer, DEFAULT_INITIAL_STATE)

  const setPage = async (pageNumber: number) =>
    dispatch({ type: ActionTypes.SetPage, payload: { pageNumber } })

  const setDateRange = async (range: RangeFilter) =>
    dispatch({ type: ActionTypes.SetDateRange, payload: { range } })

  const setSort = async (sortBy: AdminAnalyticsSortBy, sortDirection: 'asc' | 'desc') =>
    dispatch({ type: ActionTypes.SetSort, payload: { sortBy, sortDirection } })

  const setOwners = async (userIds: string[]) =>
    dispatch({ type: ActionTypes.SetOwners, payload: { ownerIds: userIds } })

  return (
    <ShareAnalyticsContext.Provider
      value={{
        setPage,
        setDateRange,
        setSort,
        setOwners,
        ...state
      }}>
      {children}
    </ShareAnalyticsContext.Provider>
  )
}

export { Provider as ShareAnalyticsProvider, ShareAnalyticsContext}