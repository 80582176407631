import api from '@api'
import { ShareAnalyticsRequestOptions, ShareAnalyticsSearchResponse } from '../@types/analytics/analytics-share.ts'
import { TimelineOptions, TimelineResponse } from '../@types/analytics/timeline-events.ts'

export const getShareAnalytics = async (options: ShareAnalyticsRequestOptions) => {
  const response = await api.post<ShareAnalyticsSearchResponse>('/api/v1/analytics/shares/search', options)
  return response.data
}

export const getTimelineForContentRoom = async (contentRoomId: string, options: TimelineOptions) => {
  const response = await api.post<TimelineResponse>(`/api/v1/analytics/shares/${contentRoomId}/timeline`, options)
  return response.data
}