import { IconButton, Paper, Snackbar, Stack, Tooltip, Typography } from '@mui/material'
import Iconify from '../Iconify.tsx'
import { useContext } from 'react'
import { SearchContext } from '../../contexts/SearchContext.tsx'
import { BulkDeleteButton } from './action-buttons/BulkDeleteButton.tsx'
import { BulkEditTagsButton } from './action-buttons/BulkEditTagsButton.tsx'

export function SearchBulkActionBar() {
  const { selectedItems, selectionModeEnabled, setSelectionModeEnabled, clearSelectedItems } = useContext(SearchContext)
  const actionBarOpen = selectionModeEnabled && selectedItems.length > 0
  const handleActionBarClose = () => {
    clearSelectedItems()
    setSelectionModeEnabled(false)
  }

  return (<>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={actionBarOpen}
        key='mutli-select-action-bar'
      >
        <Paper elevation={2} sx={{ px: 2, py: 1, mb: 2, backgroundColor: 'grey.700' }}>
          <Stack
            direction='row'
            spacing={4}
            alignItems='center'
          >
            <Typography variant='standardHighlight'>{selectedItems.length} assets selected</Typography>
            <Stack
              direction='row'
              alignItems='center'
            >
              <BulkEditTagsButton />
              <BulkDeleteButton />
              <Tooltip title='Deselect Items'>
                <IconButton
                  onClick={handleActionBarClose}
                >
                  <Iconify icon={'ph:x'} width={20} height={20} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Paper>
      </Snackbar>

    </>
  )
}