import { Asset } from '../../../../../@types/asset'
import TopUsers, { NO_LIMIT } from '../common/TopUsers'
import { TimeRange } from '../../../../../@types/analytics/analytics-common'
import { useState } from 'react'
import { TopSharer } from '../../../../../@types/analytics/shares'
import AllTopUsersDialog from '../common/AllTopUsersDialog'
import { useTopSharers } from '../../../../../hooks/analytics/useAssetShareAnalytics'
import { AudienceType } from '../../../../../@types/analytics/analytics-asset.ts'

export default function TopSharers({ asset, range, audience }: {
  asset: Asset,
  range: TimeRange,
  audience: AudienceType
}) {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { data } = useTopSharers(asset.assetId, 5, { range, audience })
  const { data: allSharersData } = useTopSharers(asset.assetId, NO_LIMIT, { range, audience }, modalOpen)
  const allSharers = allSharersData?.sharers.map(toUserListItem)
  const sharers = data?.sharers.map(toUserListItem)
  return (
    <>
      <TopUsers
        title='Top Sharers'
        list={sharers}
        moreResults={data?.hasMore}
        onSeeAllClick={() => setModalOpen(true)}
      />
      <AllTopUsersDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        users={allSharers}
      />
    </>
  )
}

function toUserListItem(it: TopSharer) {
  return {
    userId: it.userId,
    firstName: it.firstName,
    lastName: it.lastName,
    metricValue: it.numShares,
    metricLabel: it.numShares === 1 ? 'share' : 'shares',
    metricIcon: 'eva:share-fill',
  }
}