import { useContext } from 'react'
import { SearchContext } from '../../contexts/SearchContext'
import Iconify from '../Iconify'
import { IconButton, Tooltip } from '@mui/material'
import { SearchBulkActionBar } from './SearchBulkActionBar.tsx'
import { VisibleForRole } from '../../guards/VisibleForRole.tsx'
import { UserType } from '../../@types/user.ts'

export function SearchSelectionMode() {
  const { selectionModeEnabled, setSelectionModeEnabled } = useContext(SearchContext)
  const handleEnableSelection = () => setSelectionModeEnabled(true)
  const handleDisableSelection = () => setSelectionModeEnabled(false)
  return (
    <VisibleForRole roles={[UserType.ADMIN]}>
      <>
        {selectionModeEnabled && (
          <IconButton
            size='small'
            onClick={handleDisableSelection}
          >
            <Iconify icon='ph:cards-three' color='primary.main' />
          </IconButton>
        )}

        {!selectionModeEnabled && (
          <Tooltip title='Enter multi-select mode'>
            <IconButton
              size='small'
              onClick={handleEnableSelection}
            >
              <Iconify icon='ph:cards-three' color='text.deemphasized' />
            </IconButton>
          </Tooltip>
        )}

        <SearchBulkActionBar />
      </>
    </VisibleForRole>)

}