import { useFormContext, useWatch } from 'react-hook-form'
import { useMemo } from 'react'
import { CategoryValueFormData } from '../../components/asset/form/AssetUpdateFormProvider'

export function useSelectedCategoryValues() {
  const { setValue } = useFormContext<CategoryValueFormData>()
  const selectedCategoryValues = useWatch<CategoryValueFormData>({ name: 'selectedCategoryValueUUIDs' }) as string[]
  const partiallySelectedCategoryValues = (useWatch<CategoryValueFormData>({ name: 'partiallySelectedCategoryValueUUIDs' }) || []) as string[]

  function toSet(items: string[]) {
    const set = new Set()
    items && items.forEach(it => set.add(it))
    return set
  }

  const selectedValuesAsSet = useMemo(() => {
    return toSet(selectedCategoryValues)
  }, [selectedCategoryValues])

  const partiallySelectedValuesAsSet = useMemo(() => {
    return toSet(partiallySelectedCategoryValues)
  }, [partiallySelectedCategoryValues])

  const updateCategoryValues = (toSelect: string[], selected: boolean) => {
    const toSelectAsSet = toSet(toSelect)
    // filter to the new set of values
    const newSelectedValues = selected ?
      [...selectedCategoryValues, ...toSelect] :
      selectedCategoryValues.filter(it => !toSelectAsSet.has(it))

    // update to the new value
    setValue('selectedCategoryValueUUIDs', newSelectedValues)

    // always remove from the partial
    const newPartiallySelectedValues = partiallySelectedCategoryValues ?
      partiallySelectedCategoryValues.filter(it => !toSelectAsSet.has(it)) :
      []
    setValue('partiallySelectedCategoryValueUUIDs', newPartiallySelectedValues)
  }

  const selectCategoryValues = (values: string[]) => updateCategoryValues(values, true)
  const deselectCategoryValues = (values: string[]) => updateCategoryValues(values, false)

  return {
    selectedCategoryValues,
    selectedValuesAsSet,
    partiallySelectedCategoryValues,
    partiallySelectedValuesAsSet,
    selectCategoryValues,
    deselectCategoryValues,
    updateCategoryValues,
  }
}
