import { SearchContext } from '../../contexts/SearchContext'
import Scrollbar from '../Scrollbar'
import { Box, Divider, InputLabel, Select, SelectChangeEvent, Stack, Typography } from '@mui/material'
import { useContext } from 'react'
import SearchAssetCard from './SearchAssetCard'
import SearchCurrentTags from './SearchCurrentTags'
import SearchOptionsHeader from './SearchOptionsHeader'
import SearchResultsPagination from './SearchResultsPagination'
import RequestNewAssetCard from './RequestNewAssetCard'

export default function SearchResults() {
    const {results} = useContext(SearchContext)
    return (
        <Scrollbar>
            <Box component="main">
                <SearchCurrentTags/>
                <Divider sx={{mt: 3, mb: 4}}/>
                <Box sx={{mb: 5}} >
                   <SearchOptionsHeader />
                </Box>


                {/* results */}
                <Box
                    sx={{
                        display: 'grid',
                        gap: 3,
                        gridTemplateColumns: {
                            xs: 'repeat(1, 1fr)',
                            sm: 'repeat(2, 1fr)',
                            md: 'repeat(3, 1fr)',
                            lg: 'repeat(4, 1fr)',
                        },
                    }}
                >
                    {results.map((asset) => {
                        return (
                            <SearchAssetCard key={asset.assetId} asset={asset}/>
                        )
                    })}

                  <RequestNewAssetCard />
                </Box>

              <Stack
                sx={{ mt: 5 }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <PageSizeSelect/>
                <SearchResultsPagination scrollOnPageChange />
              </Stack>
            </Box>
        </Scrollbar>
    )
}

function PageSizeSelect(){
  const {filters: { pageSize }, setPageSize} = useContext(SearchContext)
  const handlePageSizeChange = (ev: SelectChangeEvent) => setPageSize(parseInt(ev.target.value, 10))
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
    >
      <InputLabel id="page-size-label"><Typography variant="small">Page Size</Typography></InputLabel>
      <Select
        size="small"
        native={true}
        value={pageSize + ""}
        onChange={handlePageSizeChange}
      >
        <option value="15">15</option>
        <option value="30">30</option>
        <option value="50">50</option>
      </Select>
    </Stack>
  )
}