import { Asset } from '../../../@types/asset'
import { TimeRange } from '../../../@types/analytics/analytics-common'
import { Grid } from '@mui/material'
import TotalDownloads from '../../asset-detail/tabs/analytics/download/TotalDownloads'
import DownloadRank from '../../asset-detail/tabs/analytics/download/DownloadRank'
import BucketedDownloads from '../../asset-detail/tabs/analytics/download/BucketedDownloads'
import TopDownloaders from '../../asset-detail/tabs/analytics/download/TopDownloaders'
import { AudienceType } from '../../../@types/analytics/analytics-asset.ts'

export default function DownloadMetricsTableRowLayout({ asset, range, audience }: {
  asset: Asset,
  range: TimeRange,
  audience: AudienceType
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TotalDownloads asset={asset} range={range} audience={audience} />
      </Grid>
      <Grid item xs={6}>
        <DownloadRank asset={asset} range={range} audience={audience} />
      </Grid>
      <Grid item xs={6}>
        <BucketedDownloads asset={asset} range={range} audience={audience} />
      </Grid>
      <Grid item xs={6}>
        <TopDownloaders asset={asset} range={range} audience={audience} />
      </Grid>
    </Grid>
  )
}