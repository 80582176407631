import { Stack, SxProps, ToggleButton, Tooltip, Typography } from '@mui/material'
import Iconify from '../Iconify'
import { Theme } from '@mui/material/styles'

type ToggleTagProps = {
  selected: boolean,
  partial?: boolean,
  categoryValueId: string,
  displayText: string,
  onChange: (categoryValueId: string, selected: boolean) => void
  sx?: SxProps<Theme>
}
export default function ToggleTag(
  {
    selected,
    partial,
    categoryValueId,
    displayText,
    onChange,
    sx,
  }: ToggleTagProps,
) {
  const partialStyling = partial ? { borderStyle: 'dashed', opacity: .8 } : {}
  const icon = partial ? 'ph:minus-circle' :
    selected ? 'eva:checkmark-fill' :
      'eva:radio-button-off-fill'
  return (
    <ToggleButton
      value={categoryValueId}
      selected={selected}
      color='tag'
      size='small'
      sx={{
        ...partialStyling,
        ...sx,
      }}
      onChange={() => {
        onChange(categoryValueId, !selected)
      }}
    >
      <Tooltip title={partial ? 'This is partially selected' : ''}>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Iconify icon={icon} />
          <Typography variant='small'>{displayText}</Typography>
        </Stack>
      </Tooltip>
    </ToggleButton>
  )
}