import { Asset } from '../../../../../@types/asset'
import { TimeRange } from '../../../../../@types/analytics/analytics-common'
import TotalWidget from '../common/TotalWidget'
import ShareIllustration from '../../../../../assets/illustration_share'
import { useAssetTotalShares } from '../../../../../hooks/analytics/useAssetShareAnalytics'
import { AudienceType } from '../../../../../@types/analytics/analytics-asset.ts'

export default function TotalShares({ asset, range, audience }: {
  asset: Asset,
  range: TimeRange,
  audience: AudienceType
}) {
  const { data, isLoading } = useAssetTotalShares(asset.assetId, { range, audience })

  if (isLoading) {
    return <></>
  }

  return (
    <TotalWidget
      title='Total Shares'
      total={data?.totalShares || 0}
      icon={<ShareIllustration />}
    />
  )
}