import api from '@api'
import {
  AcceptTermsOfServiceResponse,
  BootstrapResponse,
  DiscoveryResponse,
  LoginResponse,
  SAMLRedirectDiscoveryResponse,
} from '../@types/auth'

const DEFAULT_BOOTSTRAP_RESPONSE = {} as BootstrapResponse

export async function getBootstrap(){
  try{
    const response = await api.get<BootstrapResponse>("/api/v1/bootstrap")
    return response.data
  }catch(e){
    return DEFAULT_BOOTSTRAP_RESPONSE
  }
}

export async function acceptTermsOfService(){
  const response = await api.post<AcceptTermsOfServiceResponse>("/api/v1/bootstrap/accept-tos")
  return response.data
}

export async function login(username: string, password: string) {
  const response = await api.post<LoginResponse>("/api/v1/login", { username, password })
  return response.data
}

export async function discoverLoginSettings(username: string) {
  const response = await api.post<DiscoveryResponse>("/api/v1/login/discovery", { username })
  return response.data
}

export async function discoverSAMLRedirectUrl(username: string) {
  const response = await api.post<SAMLRedirectDiscoveryResponse>("/api/v1/login/discovery/saml-redirect", { username })
  return response.data
}

export async function logout() {
  const response = await api.post("/api/v1/logout")
  return response.data
}