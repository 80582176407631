import { TimeRange } from '../../@types/analytics/analytics-common'
import { useQuery } from '@tanstack/react-query'
import { getBucketedShares, getShareRank, getTopSharers, getTotalShares } from '../../clients/AssetAnalyticShareClient'
import { AudienceType } from '../../@types/analytics/analytics-asset.ts'

export function useAssetTotalShares(assetId: string, options: { range: TimeRange, audience: AudienceType }) {
  return useQuery({
    queryKey: ['total-shares', assetId, options],
    queryFn: async () => getTotalShares(assetId, options),
    refetchOnWindowFocus: false,
  })
}

export function useShareRank(assetId: string, options: { range: TimeRange, audience: AudienceType }) {
  return useQuery({
    queryKey: ["share-rank", assetId, options],
    queryFn: async () => getShareRank(assetId, options),
    refetchOnWindowFocus: false,
  });
}

export function useBucketeShares(assetId: string, options: { range: TimeRange, audience: AudienceType }){
  return useQuery({
    queryKey: ['bucketed-shares', assetId, options],
    queryFn: async () => getBucketedShares(assetId, options),
    refetchOnWindowFocus: false,
  })
}

export function useTopSharers(assetId: string, limit: number, options: { range: TimeRange, audience: AudienceType }, enabled: boolean = true){
  return useQuery({
    queryKey: ['top-sharers', assetId, limit, options],
    queryFn: async () => getTopSharers(assetId, options, limit),
    refetchOnWindowFocus: false,
    enabled: enabled
  })
}