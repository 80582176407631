import { ShareAnalyticsContext, ShareAnalyticsFilters } from '../../contexts/ShareAnalyticsContext.tsx'
import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { getShareAnalytics, getTimelineForContentRoom } from '../../clients/ShareAnalyticsClient.ts'
import { ShareAnalyticsSearchResponse } from '../../@types/analytics/analytics-share.ts'

export const PLACEHOLDER_SHARE_ANALYTICS = {
  results: [],
  totalResultCount: 0,
  totalDownloads: 0,
  totalViews: 0,
  totalOpens: 0
} as ShareAnalyticsSearchResponse


export function useShareAnalytics(){
  const context = useContext(ShareAnalyticsContext)
  if(!context){
    throw Error("useShareAnalytics must be used inside of ShareAnalyticsContext")
  }
  const options = toOptions(context.filters)
  return useQuery({
    queryKey: ['share-analytics', options],
    queryFn: async() => getShareAnalytics(options),
    staleTime: 30000,
  })
}

function toOptions(filters: ShareAnalyticsFilters){
  const filterArr = [
    { filterType: 'BOUNDING_DATE', start: filters.boundingStartDate, end: filters.boundingEndDate },
    { filterType: 'CREATOR', userIds: filters.ownerIds }
  ] as any[]
  return {
    sortBy: filters.sortBy,
    sortDirection: filters.sortDirection,
    filters: filterArr,
    offset: filters.currentPage * filters.pageSize,
    limit: filters.pageSize,
  }
}

export function useContentRoomTimeline(contentRoomId: string, limit: number = 5, enabled: boolean = true) {
  const { filters: { boundingStartDate, boundingEndDate } } = useContext(ShareAnalyticsContext)
  const range = { start: boundingStartDate, end: boundingEndDate }
  const options = { range, limit }
  return useQuery({
    queryKey: ['content-room', contentRoomId, 'timeline', options],
    queryFn: async () => getTimelineForContentRoom(contentRoomId, options),
    refetchOnWindowFocus: false,
    enabled: enabled,
  })
}

