import { useEffect, useMemo } from 'react'
import * as Sentry from "@sentry/react";

//
import { useBootstrap } from './useBootstrap'
import { DEFAULT_SHARING_ACCESS_TYPE, DEFAULT_SHARING_SHARE_LEVEL } from '../utils/appConstants'

// ----------------------------------------------------------------------

const useAuth = () => {
  const { data: bootstrap, isLoading, isError, isSuccess } = useBootstrap()

  // this is meant to be backward to the old auth context state
  // despite being powered by react-query now instead of a reducer
  const authState = useMemo(() => {
    const availableCompanies = bootstrap?.companies || []
    const company = availableCompanies.length === 1 ? availableCompanies[0] :
      availableCompanies.find(cmp => cmp.companyId === bootstrap?.activeCompanyId)
    return {
      isAuthenticated: Boolean(bootstrap?.user),
      isLoading: isLoading,
      user: bootstrap?.user,
      company: company,
      availableCompanies: availableCompanies,
      settings: bootstrap?.settings || DEFAULT_SETTINGS,
    }
  }, [bootstrap, isLoading])


  useEffect(() => {
    if(isError){
      Sentry.setUser(null)
      return
    }

    const user = bootstrap?.user ? { id: bootstrap?.user?.userId } : null
    Sentry.setUser(user)
  }, [bootstrap, isError, isSuccess])
  return authState
}

export default useAuth

export function useIsUsernamePasswordEnabled(){
  const { settings } = useAuth()
  return settings.usernamePasswordEnabled
}

export function useRole(){
  const { user } = useAuth()
  return user?.userType
}


const DEFAULT_SETTINGS = {
  defaultAssetGeneralAccessType: DEFAULT_SHARING_ACCESS_TYPE,
  defaultAssetShareLevel: DEFAULT_SHARING_SHARE_LEVEL,
  scimEnabled: false,
  usernamePasswordEnabled: true
}