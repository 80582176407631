import { AdminAnalyticsAssetResult } from '../../../@types/analytics/analytics-asset'
import { Box, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material'
import { useContext, useMemo, useState } from 'react';
import { useAsset } from '../../../hooks/useAsset'
import { AdminAnalyticsContext } from '../../../contexts/AdminAnalyticsContext'
import DownloadMetricsTableRowLayout from './DownloadMetricsTableRowLayout'
import ShareMetricsTableRowLayout from './ShareMetricsTableRowLayout'
import ViewMetricsTableRowLayout from './ViewMetricsTableRowLayout'


type MetricType = 'download' | 'share' | 'view'

export function AssetAnalyticsTableRowDetails({ asset: analyticsAsset }: { asset: AdminAnalyticsAssetResult }) {
  const { data: asset } = useAsset(analyticsAsset.assetId)
  const { filters: { boundingStartDate, boundingEndDate, audienceType} } = useContext(AdminAnalyticsContext)
  const [metric, setMetric] = useState<MetricType>('download')
  const range = useMemo(() => ({ start: boundingStartDate, end: boundingEndDate}), [boundingStartDate, boundingEndDate])

  const handleMetricChange = (event: SelectChangeEvent) => {
    setMetric(event.target.value as MetricType)
  }

  if (!asset) {
    return null
  }

  return (
    <Stack spacing={2}>
      <Box>
        <Select
          size='small'
          value={metric}
          defaultValue={metric}
          onChange={handleMetricChange}
        >
          <MenuItem value='download'>Downloads</MenuItem>
          <MenuItem value='share'>Shares</MenuItem>
          <MenuItem value='view'>Views</MenuItem>
        </Select>
      </Box>

      <Box>
        {metric === 'download' && <DownloadMetricsTableRowLayout asset={asset} range={range} audience={audienceType} />}
        {metric === 'share' && <ShareMetricsTableRowLayout asset={asset} range={range} audience={audienceType} />}
        {metric === 'view' && <ViewMetricsTableRowLayout asset={asset} range={range} audience={audienceType} />}
      </Box>
    </Stack>
  )
}