import { Stack } from '@mui/material'
import Iconify from '../Iconify'
import { useContext, useState } from 'react'
import { AdminAnalyticsContext } from '../../contexts/AdminAnalyticsContext'
import { Range } from 'react-date-range'
import { DateRangeDropdown } from '../date-range/DateRangeDropdown'
import { TagPicker } from './TagPicker'
import AssetAnalyticsPagination from './AssetAnalyticsPagination'
import { AudienceType, CategoryValueFilterMode } from '../../@types/analytics/analytics-asset'
import { AnalyticsCustomFilter } from './AnalyticsCustomFilter'
import { AddFilterButton } from './AnalyticsAddFilterButton'
import { LoadingButton } from '@mui/lab'
import { useAssetAnalyticsExport } from '../../hooks/analytics/useAssetAnalytics'
import { useIsFeatureEnabled } from '../../hooks/useFeatures.ts'
import { AudienceSelect } from '../audience/AudienceSelect.tsx'

export default function AssetAnalyticsFilterBar() {
  const { filters: { customFilters } } = useContext(AdminAnalyticsContext)
  const { exportAnalytics } = useAssetAnalyticsExport()
  const [isExporting, setIsExporting] = useState<boolean>(false)
  const handleExport = async () => {
    setIsExporting(true)
    await exportAnalytics()
    setIsExporting(false)
  }
  return (
    <Stack direction='column' spacing={1}>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <DefaultFilters />
        <Stack direction='row' spacing={2}>
          <LoadingButton
            variant='text'
            size='small'
            color='inherit'
            startIcon={<Iconify icon={'eva:download-outline'} />}
            onClick={handleExport}
            loading={isExporting}
            loadingPosition="start"
          >
            Export
          </LoadingButton>
          <AssetAnalyticsPagination />
        </Stack>
      </Stack>
      { Boolean(customFilters.length) && (
        <Stack direction='row' alignItems='center' spacing={1}>
          {customFilters.map(it => {
            return (
              <AnalyticsCustomFilter key={it.id} filter={it} />
            )
          })}
        </Stack>
      )}

    </Stack>
  )
}


function DefaultFilters() {
  const externalSharingEnabled = useIsFeatureEnabled('external-sharing')

  // context
  const {
    setDateRange,
    addTagFilter,
    setAudience,
    filters: { audienceType, boundingStartDate, boundingEndDate },
  } = useContext(AdminAnalyticsContext)

  // handlers
  const handleRangeChange = (range: Range) => setDateRange({ startDate: range.startDate, endDate: range.endDate })
  const handleIncludedTagsChange =
    (categoryValueIds: string[], mode: CategoryValueFilterMode) => addTagFilter(categoryValueIds, true, mode, false)
  const handleAudienceChange = (audience: AudienceType) => setAudience(audience)

  // calculated properties
  const defaultRange = { startDate: boundingStartDate, endDate: boundingEndDate, key: 'selection' } as Range

  return (
    <Stack
      direction='row'
      spacing={3}
      alignItems='center'
    >
      <DateRangeDropdown
        onChange={handleRangeChange}
        defaultRange={defaultRange}
      />
      <TagPicker
        initialSelectedIds={[]}
        initialMode={CategoryValueFilterMode.ONE_OF}
        onChange={handleIncludedTagsChange}
      />
      {externalSharingEnabled && (
        <AudienceSelect
          value={audienceType}
          onChange={handleAudienceChange}
        />
      )}
      <AddFilterButton />
    </Stack>
  )
}


