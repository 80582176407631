import { Asset } from '../../../../@types/asset'
import { TimeRange } from '../../../../@types/analytics/analytics-common'
import { Grid } from '@mui/material'
import BucketedDownloads from './download/BucketedDownloads'
import TopDownloaders from './download/TopDownloaders'
import TotalDownloads from './download/TotalDownloads'
import DownloadRank from './download/DownloadRank'
import { AudienceType } from '../../../../@types/analytics/analytics-asset.ts'

export default function DownloadMetricsAssetDetailsLayout({ asset, range, audience }: {
  asset: Asset,
  range: TimeRange,
  audience: AudienceType
}) {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={6}>
        <TotalDownloads asset={asset} range={range} audience={audience} />
      </Grid>
      <Grid item xs={12} sm={12} md={6}>
        <DownloadRank asset={asset} range={range} audience={audience} />
      </Grid>
      <Grid item xs={12}>
        <BucketedDownloads asset={asset} range={range} audience={audience} />
      </Grid>
      <Grid item xs={12}>
        <TopDownloaders asset={asset} range={range} audience={audience} />
      </Grid>
    </Grid>
  )
}