import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip } from '@mui/material'
import Iconify from '../../Iconify.tsx'
import { useContext, useEffect, useState } from 'react'
import { SearchContext } from '../../../contexts/SearchContext.tsx'
import { LoadingButton } from '@mui/lab'
import { useBulkAssetTagState, useBulkUpdateAssetTags } from '../../../hooks/useAsset.ts'
import { useForm } from 'react-hook-form'
import { CategoryValueFormData } from '../../asset/form/AssetUpdateFormProvider.tsx'
import UpdateAssetTags from '../../../pages/asset/UpdateAssetTags.tsx'
import { FormProvider } from '../../hook-form'

export function BulkEditTagsButton() {
  const [open, setOpen] = useState<boolean>(false)
  const handleUpdateClick = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip title='Edit tags'>
        <IconButton onClick={handleUpdateClick}>
          <Iconify icon={'ph:tag'} width={20} height={20} />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        maxWidth='sm'
        fullWidth={true}
        onClose={handleClose}
        sx={{zIndex: 1401}}
      >

        <DialogTitle>
          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={handleClose}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>
        </DialogTitle>

        <CategoryUpdateForm open={open} onClose={handleClose} />

      </Dialog>
    </>
  )
}

function CategoryUpdateForm({ open, onClose }: { open: boolean, onClose: () => void }) {
  const { selectedItems: assetIds, refreshAfterBulkAction } = useContext(SearchContext)
  const { data: tags } = useBulkAssetTagState(assetIds, open)
  const { mutateAsync: bulkUpdateAssetTags, isLoading: saving } = useBulkUpdateAssetTags()

  const methods = useForm<CategoryValueFormData>({
    defaultValues: {
      selectedCategoryValueUUIDs: tags?.selectedCategoryValueIds || [],
      partiallySelectedCategoryValueUUIDs: tags?.partialSelectedCategoryValueIds || [],
    },
    mode: 'onChange',
  })

  useEffect(() => methods.reset({
    selectedCategoryValueUUIDs: tags?.selectedCategoryValueIds || [],
    partiallySelectedCategoryValueUUIDs: tags?.partialSelectedCategoryValueIds || [],
  }), [tags])

  const handleClose = () => onClose()

  const handleConfirmation = (data: CategoryValueFormData) => {
    bulkUpdateAssetTags({
      assetIds: assetIds,
      selectedCategoryValueIds: data.selectedCategoryValueUUIDs,
      preservedCategoryValueIds: data.partiallySelectedCategoryValueUUIDs || []
    }).then(() => {
      refreshAfterBulkAction()
      handleClose()
    })
  }

  return (
    <FormProvider methods={methods}>
      <DialogContent sx={{maxHeight: "calc(70vh - 200px)", overflowY: "auto"}}>
        <UpdateAssetTags />
      </DialogContent>

      <DialogActions sx={{flexDirection: "column"}}>
        <LoadingButton
          loading={saving}
          fullWidth={true}
          variant='contained'
          color='primary'
          size='large'
          onClick={methods.handleSubmit(handleConfirmation)}
        >
          Update Tags
        </LoadingButton>
        <Button
          fullWidth={true}
          variant='outlined'
          size='large'
          onClick={handleClose}
          sx={{marginTop: 2}}
        >
          Cancel
        </Button>
      </DialogActions>
    </FormProvider>
  )

}