import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import Iconify from '../../Iconify.tsx'
import { LoadingButton } from '@mui/lab'
import { useContext, useState } from 'react'
import { SearchContext } from '../../../contexts/SearchContext.tsx'
import { useBulkDeleteAssets } from '../../../hooks/useAsset.ts'

type Props = {}

export function BulkDeleteButton({}: Props) {
  const [open, setOpen] = useState<boolean>(false)
  const { mutateAsync: deleteAssets, isLoading: deleting } = useBulkDeleteAssets()
  const { selectedItems, refreshAfterBulkAction } = useContext(SearchContext)
  const handleDeleteClick = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleConfirmation = () => {
    deleteAssets({ assetIds: selectedItems} )
      .then(() => {
        refreshAfterBulkAction()
        handleClose()
      })
  }

  return (
    <>
      <Tooltip title='Delete assets'>
        <IconButton
          onClick={handleDeleteClick}
        >
          <Iconify icon={'ph:trash'} width={20} height={20} />
        </IconButton>
      </Tooltip>

      <Dialog
        open={open}
        maxWidth='xs'
        fullWidth={true}
        onClose={handleClose}
      >

        <DialogTitle>
          <Typography variant='h2' paragraph>
            {
              selectedItems.length == 1 ?
                'Are you sure you want to delete this asset?' :
                `Are you sure you want to delete these ${selectedItems.length} assets?`
            }
          </Typography>

          <Box sx={{ position: 'absolute', right: 0, top: 0, mr: 1, mt: 1 }}>
            <IconButton onClick={handleClose}>
              <Iconify icon='eva:close-fill' />
            </IconButton>
          </Box>
        </DialogTitle>


        <DialogContent>
          <Typography variant='standard' color='text.secondary' component='div' mb={4}>
            This action is permanent and cannot be undone.
          </Typography>
          <Stack spacing={2}>
            <LoadingButton
              loading={deleting}
              fullWidth={true}
              variant='contained'
              color='primary'
              size='large'
              onClick={handleConfirmation}
            >
              Delete Asset
            </LoadingButton>
            <Button
              fullWidth={true}
              variant='outlined'
              size='large'
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}