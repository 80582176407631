import { Box, Grid } from '@mui/material'
import { AnalyticsShareResult } from '../../../../@types/analytics/analytics-share.ts'
import { ContentRoomTimeline } from './ContentRoomTimeline.tsx'


export function ShareAnalyticsTableRowDetails({ share }: { share: AnalyticsShareResult }) {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ContentRoomTimeline contentRoomId={share.contentRoomId} />
        </Grid>
      </Grid>
    </Box>
  )
}