import Page from '../../components/Page.tsx'
import { Box, Container, Link, Paper, Stack, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import LogoFull from '../../components/LogoFull.tsx'
import { styled } from '@mui/material/styles'
import { useAcceptTermsOfService } from '../../hooks/useBootstrap.ts'
import { LoadingButton } from '@mui/lab'
import Image from '../../components/Image.tsx'

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
  background: theme.palette.background.default,
}))

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 425,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))


export function TermsOfServiceAcceptance() {
  const { mutateAsync: acceptToS, isLoading } = useAcceptTermsOfService()
  const handleToSAccept = () => acceptToS()
  return (
    <Page title='Accept Terms of Service'>
      <RootStyle>

        <Container maxWidth='sm'>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.35 }}
          >
            <ContentStyle>
              <Paper elevation={4} sx={{ p: 4 }}>
                <Stack spacing={4}>
                  <LogoFull sx={{ px: 8 }} />

                  <Typography variant='h2'>
                    Accept Terms and Conditions
                  </Typography>

                  <Box sx={{ px: 4 }}>
                    <Image
                      disabledEffect
                      visibleByDefault
                      backgroundType='contain'
                      alt='empty content'
                      src='/assets/illustrations/illustration_terms.svg'
                      sx={{ height: 150, p: 2, background: '#f1f1f1', borderRadius: 2 }}

                    />
                  </Box>

                  <Stack
                    direction='column'
                    spacing={1}
                  >
                    <Typography variant='standard'>To proceed, please accept the Masset <Link
                      href='https://www.getmasset.com/terms-of-service' target='_blank'>Terms and
                      Conditions.</Link></Typography>
                    <Typography variant='standard'>It is important that you read and understand them before continuing
                      to use our Services.</Typography>
                  </Stack>

                  <Stack direction='row' justifyContent='end'>
                    <LoadingButton
                      onClick={handleToSAccept}
                      variant='contained'
                      loading={isLoading}
                    >Accept & Continue</LoadingButton>

                  </Stack>
                </Stack>

              </Paper>

            </ContentStyle>
          </motion.div>
        </Container>
      </RootStyle>
    </Page>
  )
}